<template>
  <widget-wrapper
    title="Búsqueda de foro"
    widget-id="widget-forum-search"
    widget-name="Búsqueda de foro"
    :has-help="false"
    theme="dark"
    @close-widget="_closeWidget"
  >
    <!-- Loading State -->
    <div v-if="loading" class="widget-loading">
      <div class="spinner"></div>
    </div>
    
    <!-- Forum Search Iframe -->
    <div v-else class="forum-search-content">
      <iframe
        id="iframe-forum-search"
        frameborder="0"
        scrolling="yes"
        :src="forumSearchUrl"
        allow-top-navigation-without-user-activation
      ></iframe>
    </div>
  </widget-wrapper>
</template>

<script>
import { mapGetters } from "vuex";
import WidgetWrapper from "@/components/widgets/WidgetWrapper.vue";

export default {
  name: "ForumSearch",
  components: {
    WidgetWrapper
  },
  data() {
    return {
      loading: true,
      widget_name: { 
        id: "widget-forum-search", 
        name: "Búsqueda de foro" 
      },
      forumSearchUrl: ""
    };
  },
  mounted() {
    this._loadForumSearch();
  },
  computed: {
    ...mapGetters(["is_mobile_device"]),
  },
  methods: {
    _loadForumSearch() {
      this.loading = true;
      this.forumSearchUrl = process.env.VUE_APP_API + "/forum/search";
      
      // Use a small timeout to show loading state before iframe loads
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    
    _closeWidget() {
      this.$emit("widget_name", this.widget_name);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/widgets/WidgetStyles.scss";

.widget-loading {
  @extend .widget-loading-base;
  background-color: var(--color-background, #0F0F1A);
}

.spinner {
  @extend .loading-spinner;
}

.forum-search-content {
  height: 100%;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  
  iframe {
    flex: 1;
    width: 100%;
    height: 100%;
    border: none;
    background-color: var(--color-surface, #20202B);
  }
}
</style>
