<template>
  <widget-wrapper
    title="Ultimas IPOs Eurekers"
    widget-id="widget-last-ipos"
    widget-name="Últimas IPOs Eurekers"
    :gd-url="gd_url"
    :has-help="true"
    :help-url="url_iframe"
    :help-title="help_tittle"
    theme="dark"
    @close-widget="_closeWidget"
    @show-help="_sendDataToIframe"
  >
    <!-- Loading State -->
    <div v-if="loading" class="widget-loading">
      <div class="spinner"></div>
    </div>
    
    <!-- Content When Data is Available -->
    <div v-else-if="ipos.length > 0" class="ipos-list-container">
      <div class="ipo-list-header">
        <span class="col-ticker">Ticker</span>
        <span class="col-date">Fecha</span>
        <span class="col-rentab">Rentab.</span>
      </div>
      <div class="ipo-list">
        <div 
          v-for="(ipo, index) in ipos" 
          :key="index"
          class="ipo-item"
          @click="openIpoGD(ipo.ticker)"
        >
          <span class="col-ticker">{{ ipo.ticker }}</span>
          <span class="col-date">{{ ipo.date }}</span>
          <span class="col-rentab">{{ ipo.rentab }}%</span>
        </div>
      </div>
    </div>
    
    <!-- No Data Message -->
    <div v-else class="no-data">
      <p>No hay IPOs recientes disponibles.</p>
    </div>
  </widget-wrapper>
</template>

<script>
import { mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";
import WidgetWrapper from "@/components/widgets/WidgetWrapper.vue";

export default {
  mixins: [APICaller],
  components: {
    WidgetWrapper
  },
  name: "LastIPOs",
  data() {
    return {
      gd_url: "",
      ipos: [],
      loading: true,
      widget_name: { id: "widget-last-ipos", name: "Últimas IPOs Eurekers" },
      url_iframe: "https://player.vimeo.com/video/187305383?title=0&byline=0&portrait=0&autoplay=1",
      help_tittle: "Ayuda sobre últimas IPOs",
    };
  },
  mounted() {
    this.gd_url = this.$detectEnvironment() + "/app/graficador-dinamico/last-ipos";
    this._getLastIPOs();
  },
  computed: {
    ...mapGetters(["is_mobile_device"]),
  },
  methods: {
    _sendDataToIframe: function() {
      this.$emit("url_iframe", this.url_iframe);
      this.$emit("help_tittle", this.help_tittle);
      this.$emit("load_iframe");
    },

    _getLastIPOs() {
      this.loading = true;
      let success = (response) => {
        const data = response.data || {};
        this.ipos = Object.values(data).map(item => {
          let rentab = parseFloat(item.tae_lt);
          return {
            ticker: item.ticker !== '---' ? item.ticker : 'N/A',
            date: item.fecha_primera_barra.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1'),
            rentab: rentab > 1000 ? rentab.toFixed(0) : rentab.toFixed(1),
          };
        }).filter(ipo => ipo.ticker !== 'N/A'); // Filter out invalid entries
        this.loading = false;
      };
      
      let failure = () => {
        this.ipos = [];
        this.loading = false;
      };

      let url = "/api/v1/stock-finder/last-ipos";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(failure, "145", "Error cargando últimas IPOs.");
      const apiCallConfig = { loadingOverlay: false };

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },

    openIpoGD(ticker) {
      if (ticker && ticker !== 'N/A') {
         window.open(this.$detectEnvironment() + "/app/graficador-dinamico/" + ticker, "_blank");
      }
    },

    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/widgets/WidgetStyles.scss";

.widget-loading {
  @extend .widget-loading-base;
  background-color: var(--color-background, #0F0F1A);
}

.spinner {
  @extend .loading-spinner;
}

.no-data {
  @extend .empty-state;
  background-color: var(--color-background, #0F0F1A);
  
  p {
    color: var(--color-text-secondary, #BFC2CD);
    font-size: 14px;
    text-align: center;
    padding: 0 20px;
    max-width: 300px;
  }
}

.ipos-list-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.ipo-list-header {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 600;
  color: var(--color-text-secondary, #BFC2CD);
  border-bottom: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
  background-color: var(--color-surface, #20202B);
  flex-shrink: 0;
}

.ipo-list {
  flex: 1;
  overflow-y: auto;
  padding: 8px 16px;
}

.ipo-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  font-size: 13px;
  border-bottom: 1px solid var(--color-outline, rgba(255, 255, 255, 0.1));
  cursor: pointer;
  transition: background-color 0.15s ease;
  
  &:hover {
    background-color: var(--color-active-surface, #193141);
  }
  
  &:last-child {
    border-bottom: none;
  }
}

// Column widths and colors
.col-ticker { 
  width: 35%; 
  font-weight: 500; 
  color: var(--color-text-primary, #FFFFFF); 
}

.col-date { 
  width: 35%; 
  text-align: center; 
  color: var(--color-text-secondary, #BFC2CD); 
}

.col-rentab { 
  width: 25%; 
  text-align: right; 
  font-weight: 600; 
  color: var(--primary, #00aedd); 
}
</style>
