<template>
    <div v-if="_g_IsUserLogged && !_g_User.is_only_authenticated_user">
        <div class="container" v-if="_g_UserFeatures.permissions.control_panel_tools">
            <!-- Configuration Button -->
            <div class="config-bar">
                <h1 class="dashboard-title">Panel de control</h1>
                <button @click="showConfigModal = true" class="config-button">
                    <span class="material-icons">settings</span>
                    Configurar widgets
                </button>
            </div>

            <!-- Widget Grid -->
            <div class="widget-grid">
                <div v-for="widgetId in displayedWidgets" :key="widgetId" class="widget-container">
                     <component 
                        :is="widgetId" 
                        class="widget-component" 
                        @widget_name="handleWidgetClose($event)" 
                        @url_iframe="url_iframe=$event" 
                        @help_tittle="help_tittle=$event" 
                        @load_iframe="load_iframe"
                     ></component>
                 </div>
            </div>
            
            <!-- Help Iframe Popup (Existing) -->
            <div v-if="show_help" class="close-div" @click="_closeHelpIframe">
                <div class="help-pop-up">
                    <iframe :src="url_iframe" width="100%" height="344" frameborder="0" allowfullscreen=""></iframe>
                    <span style="color: grey; font-weight: bold">{{help_tittle}}</span>
                    <span class="material-icons-outlined close-help-icon" @click="_closeHelpIframe()">close</span>
                </div>
            </div>
            
            <!-- Configuration Modal -->
            <div v-if="showConfigModal" class="modal-overlay" @click="showConfigModal = false">
                <div class="modal-container widget-config-modal" @click.stop>
                    <div class="modal-header">
                        <h3 class="modal-title">Configuración de widgets</h3>
                        <span class="material-icons close-icon" @click="showConfigModal = false">close</span>
                    </div>
                    
                    <div class="modal-body">
                        <p class="modal-instruction">Selecciona los widgets que quieres mostrar en tu panel.</p>
                        
                        <div class="widget-list" v-if="allWidgets.length > 0">
                            <div 
                                v-for="(widget, index) in allWidgets" 
                                :key="widget.id"
                                class="widget-list-item"
                                :class="{'enabled': widget.enabled}"
                            >
                                <div class="widget-list-item-info">
                                    <input 
                                        type="checkbox" 
                                        :id="'widget-' + widget.id"
                                        v-model="widget.enabled"
                                    />
                                    <label :for="'widget-' + widget.id">{{ widget.name }}</label>
                                </div>
                                
                                <div class="widget-list-item-controls">
                                    <button 
                                        class="order-button" 
                                        :disabled="index === 0"
                                        @click="moveWidgetUp(index)"
                                    >
                                        <span class="material-icons">arrow_upward</span>
                                    </button>
                                    <button 
                                        class="order-button" 
                                        :disabled="index === allWidgets.length - 1"
                                        @click="moveWidgetDown(index)"
                                    >
                                        <span class="material-icons">arrow_downward</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                        <div v-else class="widget-list-loading">
                            <span class="material-icons spinner">refresh</span>
                            <p>Cargando widgets...</p>
                        </div>
                    </div>
                    
                    <div class="modal-footer">
                        <button class="cancel-button" @click="showConfigModal = false">Cancelar</button>
                        <button class="save-button" @click="_saveWidgetConfiguration">Guardar cambios</button>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    <div v-else-if="_g_User.is_only_authenticated_user" class="not-allowed-widgets-container">
      <p class="not-allowed-widgets-title"> SIN ACCESO </p>
      <p class="not-allowed-widgets"> Su plan actual no tiene acceso a esta herramienta. <a href="/app/store" class="link-to-store"> Pulse aquí para cambiar de plan. </a> </p>
    </div>   
    <div id="main-container" class="mdc-layout-grid__cell--span-12 mdc-layout-grid__cell--span-1-phone" v-else style="text-align: center;">
        <div class="container-image mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--align-middle">      
            <img src="@/views/assets/widgets-screenshot.png">
            <div class="text-centeR">
            <h2>Panel de control</h2>
            <p>Controla tus inversiones en todo momento dentro de un mismo espacio de trabajo. Comprueba rápidamente el estado de tu/s cuenta/s, mira tus posiciones, revisa tus stops, anota tus observaciones y planifica tus próximos movimientos en función de tu metodología de inversión.</p>
            </div>
            <div class="button-container">
                <button class="login-button" @click="_redirectToLogin()">LOGIN</button>
            </div>  
        </div>        
    </div>
</template>

<script>
import widget_platinum_best_values from './widgets/widget_platinium_best_values.vue';
import widget_twin_souls from "./widgets/widget_twin_souls.vue";
import widget_best_bullish_values from "./widgets/widgets_best_bullish_values.vue";
import widget_hot_values from "./widgets/widgets_hot_values.vue";
import widget_forum_search from "./widgets/widget_forum_search.vue";
import widget_alerts from "./widgets/widget_alerts.vue";
import widget_open_positions from "./widgets/widget_open_positions.vue";
import widget_best_sectors from "./widgets/widget_best_sectors.vue";
import widget_portfolio_waiting_values from "./widgets/widget_waiting_values.vue";
import widget_portfolio_changes from "./widgets/widget_portfolio_changes.vue";
import widget_motivational_sentences from "./widgets/widget_motivational_sentences.vue";
import widget_portfolio_index from "./widgets/widget_portfolio_index.vue";
import widget_curve_capital from "./widgets/widget_curve_capital.vue";
import widget_portfolio_risk from "./widgets/widget_portfolio_risk.vue";
import widget_master_eurekers from "./widgets/widget_master_eurekers.vue";
import widget_artificial_intelligence from "./widgets/widget_artificial_intelligence.vue";
import widget_favorite_search from "./widgets/widget_favorite_search.vue";
import widget_last_ipos from "./widgets/widget_last_ipos.vue";
import widget_your_photo from "./widgets/widget_your_photo.vue";
import widget_master_class_overview from "./widgets/widget_master_class_overview.vue";
import widget_directionality from "./widgets/widget_directionality.vue";
import widget_ticker_search from "./widgets/widget_search_by_ticker.vue";
import widget_pre_eurekers_exers from "./widgets/widget_pre_eurekers_exers.vue";
import widget_advanced_course_opinions from "./widgets/widget_opiniones_curso_avanzado.vue";
import widget_why_take_advanced_course from "./widgets/widget_take_advanced_course.vue";
import widget_best_portfolio from "./widgets/widget_best_portfolio.vue";
import widget_add from "./widgets/widget_add.vue";
import APICaller from "@/components/codeComponents/APICaller.js";
import { mapGetters, mapMutations } from "vuex";
import axios from 'axios';

export default {
    mixins: [APICaller],
    components: {
        widget_twin_souls,
        widget_platinum_best_values,
        widget_best_bullish_values,
        widget_hot_values,
        widget_best_sectors,
        widget_portfolio_waiting_values,
        widget_portfolio_changes,
        widget_motivational_sentences,
        widget_portfolio_index,
        widget_curve_capital,
        widget_portfolio_risk,
        widget_master_eurekers,
        widget_artificial_intelligence,
        widget_favorite_search ,
        widget_last_ipos,
        widget_forum_search,
        widget_alerts,
        widget_open_positions,
        widget_ticker_search,
        widget_your_photo,
        widget_master_class_overview,
        widget_directionality,
        widget_why_take_advanced_course,
        widget_advanced_course_opinions,
        widget_pre_eurekers_exers,
        widget_best_portfolio,
        widget_add,
    },
    data() {
        return {
            enabledWidgets: [],
            allWidgets: [],
            widget_name: {},
            show_help: false,
            help_tittle: '',
            url_iframe: '',
            showConfigModal: false,
            is_basic_user: "",
            user_info_is_loaded: "",
            basic_user_uid:"",
        }
    },
    created(){
        if(this._g_IsUserLogged && this._g_UserFeatures.permissions.control_panel_tools){ 
            this._getWidgetConfiguration();
        }
    },
    watch:{
    },
    computed: {
        ...mapGetters(["_g_IsUserLogged", "_g_User", "_g_UserFeatures"]),
        
        displayedWidgets() {
            // Get all enabled widgets excluding widget_add if it somehow got included
            const userEnabledWidgets = this.enabledWidgets.filter(id => id !== 'widget_add');
            
            // Create a new array to avoid modifying the original
            let result = [...userEnabledWidgets];
            
            // Insert widget_add at position 4 (index 3)
            // If there are fewer than 3 widgets, pad accordingly
            if (result.length < 3) {
                // Fill with empty slots if needed to reach position 3
                while (result.length < 3) {
                    result.push(userEnabledWidgets[0] || 'widget_motivational_sentences');
                }
                result.push('widget_add');
            } else {
                // Insert at position 4 (index 3)
                result.splice(3, 0, 'widget_add');
            }
            
            return result;
        }
    },    
    methods: {
        _redirectToLogin() {
            window.location.href = "/user/login";
        },
        load_iframe: function(){
            this.show_help = true;
        },
        _closeHelpIframe: function(){
            this.show_help = false;
        },
        handleWidgetClose: function(widgetInfo) {
            // Prevent widget_add from being closed
            if (widgetInfo.id === 'widget_add' || widgetInfo.id === 'widget-add') {
                return;
            }
            
            // Find the widget in allWidgets by converting the format as needed
            const widgetId = widgetInfo.id.includes("-") 
                ? widgetInfo.id.replaceAll("-", "_") 
                : widgetInfo.id;
                
            const widgetToDisable = this.allWidgets.find(w => w.id === widgetId);
            if (widgetToDisable) {
                widgetToDisable.enabled = false;
                
                // Update the enabledWidgets array to reflect this change immediately
                this.enabledWidgets = this.allWidgets
                                      .filter(w => w.enabled)
                                      .map(w => w.id);
                                      
                this.showConfigModal = true;
            }
        },
        moveWidgetUp(index) {
            if (index > 0) {
                // Swap the widget with the one above it
                const temp = {...this.allWidgets[index-1]};
                this.$set(this.allWidgets, index-1, this.allWidgets[index]);
                this.$set(this.allWidgets, index, temp);
                
                // Update order values
                this._updateWidgetOrder();
            }
        },
        moveWidgetDown(index) {
            if (index < this.allWidgets.length - 1) {
                // Swap the widget with the one below it
                const temp = {...this.allWidgets[index+1]};
                this.$set(this.allWidgets, index+1, this.allWidgets[index]);
                this.$set(this.allWidgets, index, temp);
                
                // Update order values
                this._updateWidgetOrder();
            }
        },
        _updateWidgetOrder() {
            // Update order values based on current array positions
            this.allWidgets.forEach((widget, index) => {
                widget.order = index;
            });
        },
        _saveWidgetConfiguration: async function() {
            

            // Update the enabledWidgets array immediately for better UX
            this.enabledWidgets = this.allWidgets
                                  .filter(w => w.enabled)
                                  .map(w => w.id);

            // Prepare the payload for the bulk update endpoint
            const configToSave = this.allWidgets.map(widget => ({
                id: widget.id.replaceAll('_', '-'), // Ensure ID has hyphens
                enabled: widget.enabled,
                order: widget.order
            }));

            const url = "/api/v1/widgets/bulk-update";

            try {
                const response = await axios.post(url, configToSave, {
                    headers: {
                        'Content-Type': 'application/json',
                        // Include authentication headers if needed (e.g., JWT token)
                        // 'Authorization': `Bearer ${your_token}` 
                    }
                });

                if (response.status === 200) {
                    this.$vueOnToast.pop('success', "Configuración guardada", "Panel actualizado.");
                    this.showConfigModal = false;
                    
                    // Refresh the configuration from server to ensure everything is in sync
                    this._getWidgetConfiguration();
                } else {
                     this.$vueOnToast.pop('error', "Error guardando configuración", `Server responded with status ${response.status}`);
                }
            } catch (error) {
                console.error("Failed to save widget config:", error);
                 this.$vueOnToast.pop('error', "Error guardando configuración", "Error en la comunicación con el servidor.");
            }
        },
        _getWidgetName: function(id){
            let name = "";
            const widgetId = id.replaceAll("-","_"); 
            let widgets_names = {
                'widget_best_sectors' : 'Mejores sectores',
                'widget_curve_capital' : 'Curva de capital',
                'widget_platinum_best_values' : 'Valores Platino',
                'widget_portfolio_risk' : 'Riesgo de cartera',
                'widget_portfolio_waiting_values' : 'Valores en espera',
                'widget_hot_values' : 'Más comentados',
                'widget_directionality' : 'Direccionalidad',
                'widget_last_ipos' : 'Últimas IPOs',
                'widget_master_eurekers' : 'Master Eurekers',
                'widget_best_bullish_values': 'Mejores valores alcistas',
                'widget_your_photo': 'Tu foto',
                'widget_favorite_search': 'Búsquedas favoritas',
                'widget_twin_souls': 'Almas gemelas',
                'widget_portfolio_index': 'Índice de cartera',
                'widget_motivational_sentences': 'Citas célebres',
                'widget_master_class_overview': 'Resumen de emisiones en directo',
                'widget_ticker_search': 'Búsqueda de ticker',
                'widget_advanced_course_opinions': 'Opiniones de curso avanzado',
                'widget_why_take_advanced_course': 'Por qué hacer el curso avanzado',
                'widget_forum_search': 'Búsquedas de foro',
                'widget_pre_eurekers_exers': 'Soluciones ejercicios',
                'widget_alerts': 'Alertas',
                'widget_open_positions': 'Posiciones abiertas',
                'widget_artificial_intelligence': 'Inteligencia artificial',
                'widget_portfolio_changes': 'Cambios cartera',
                'widget_best_portfolio': 'Mejores Carteras',
            };
            name = widgets_names[widgetId] || widgetId;
            return name;
        },
        _getWidgetConfiguration: function(){
            

            let url = "/api/v1/widgets/list";

            let success = (response) => {          
                const serverConfig = response.data || [];
                
                // Get all widget components except widget_add
                // Also exclude widgets that are not in the backend
                const excludedWidgets = [
                    'widget_add',
                    'widget_why_take_advanced_course',
                    'widget_best_portfolio',
                    'widget_pre_eurekers_exers',
                    'widget_advanced_course_opinions'
                ];
                
                const availableWidgetIds = Object.keys(this.$options.components)
                                          .filter(id => id.startsWith('widget_') && !excludedWidgets.includes(id));

                const processedWidgets = availableWidgetIds.map(widgetId => {
                    const serverWidget = serverConfig.find(w => w.id.replaceAll('-', '_') === widgetId);
                    const isEnabled = serverWidget ? (serverWidget.configuration?.enabled ?? true) : false;
                    const order = serverWidget ? parseInt(serverWidget.position) : 999;
                    
                    return {
                        id: widgetId,
                        name: this._getWidgetName(widgetId),
                        enabled: isEnabled,
                        order: order,
                    };
                });

                processedWidgets.sort((a, b) => a.order - b.order);
                
                this.allWidgets = processedWidgets;
                this.enabledWidgets = this.allWidgets
                                      .filter(w => w.enabled)
                                      .map(w => w.id);
            };
            
            const failure = (error) => {
                console.error("Error fetching widget list:", error);
                 this.allWidgets = [];
                 this.enabledWidgets = [];
            }

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('215', 'Error recuperando lista de widgets.');
            this._getAPICall(url, successHandler, failureHandler, { loadingOverlay: false });
        },
}
}
</script>

<style scoped>
.container {
    background-color: var(--color-background);
    padding: 15px;
}

.config-bar {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dashboard-title {
    color: var(--color-text-primary);
    font-size: 24px;
    font-weight: 600;
    margin: 0;
}

.config-button {
    background-color: var(--color-surface);
    color: var(--color-text-secondary);
    border: 1px solid var(--color-outline);
    padding: 8px 15px;
    border-radius: 20px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    transition: all 0.2s ease;
}

.config-button:hover {
    background-color: var(--color-active-surface);
    color: var(--color-text-primary);
    border-color: var(--primary);
}

.config-button .material-icons {
    font-size: 18px;
}

.widget-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr)); 
    gap: 15px;
}

.widget-container {
    border-radius: 8px;
    overflow: hidden;
    background-color: var(--color-surface);
    border: 1px solid var(--color-outline);
    display: flex;
    height: 360px;
}

.widget-component {
    width: 100%;
    height: 100%;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(15, 15, 26, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
}

.modal-container {
    background-color: var(--color-background);
    border: 1px solid var(--color-outline);
    border-radius: 8px;
    width: 90%;
    max-width: 600px; 
    max-height: 80vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.3);
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    border-bottom: 1px solid var(--color-outline);
}

.modal-title {
    margin: 0;
    color: var(--color-text-primary);
    font-size: 18px;
    font-weight: 600;
}

.close-icon {
    color: var(--color-text-secondary);
    cursor: pointer;
    font-size: 20px;
}

.close-icon:hover {
    color: var(--color-text-primary);
}

.modal-body {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
}

.modal-instruction {
    color: var(--color-text-secondary);
    margin-bottom: 20px;
    font-size: 14px;
}

.widget-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.widget-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 15px;
    border: 1px solid var(--color-outline);
    border-radius: 6px;
    background-color: var(--color-surface);
    transition: all 0.2s ease;
}

.widget-list-item.enabled {
    border-color: var(--primary);
    background-color: rgba(0, 174, 221, 0.05);
}

.widget-list-item-info {
    display: flex;
    align-items: center;
    gap: 10px;
}

.widget-list-item label {
    color: var(--color-text-primary);
    font-size: 15px;
    cursor: pointer;
}

.widget-list-item input[type="checkbox"] {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.widget-list-item-controls {
    display: flex;
    gap: 8px;
}

.order-button {
    background: none;
    border: 1px solid var(--color-outline);
    border-radius: 4px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-text-secondary);
    transition: all 0.15s ease;
    padding: 0;
}

.order-button:hover:not(:disabled) {
    color: var(--primary);
    border-color: var(--primary);
}

.order-button:disabled {
    opacity: 0.4;
    cursor: not-allowed;
}

.order-button .material-icons {
    font-size: 16px;
}

.widget-list-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    color: var(--color-text-secondary);
}

.widget-list-loading .spinner {
    font-size: 36px;
    margin-bottom: 15px;
    animation: spin 1.5s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    padding: 16px 20px;
    border-top: 1px solid var(--color-outline);
}

.cancel-button, .save-button {
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.cancel-button {
    background: none;
    border: 1px solid var(--color-outline);
    color: var(--color-text-secondary);
}

.cancel-button:hover {
    background-color: var(--color-surface);
}

.save-button {
    background-color: var(--primary);
    border: 1px solid var(--primary);
    color: white;
}

.save-button:hover {
    background-color: #0095c5;
}

.help-pop-up {
    position: fixed;
    border: 1px solid var(--color-outline);
    background-color: var(--color-surface);
    color: var(--color-text-primary);
    width: 90%;
    max-width: 600px;
    height: auto;
    max-height: 70vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.3);
}

.help-pop-up iframe {
    display: block;
    width: 100%;
    height: 344px;
    border: none;
    margin-bottom: 10px;
}

.help-pop-up span {
     color: var(--color-text-secondary); 
     font-weight: bold;
}

.close-help-icon {
    float: right; 
    font-size: 20px; 
    margin-right: 0;
    margin-left: 10px;
    color: var(--color-text-secondary);
    cursor: pointer;
    padding: 5px;
}
.close-help-icon:hover {
    color: var(--color-text-primary);
}

.close-div {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(15, 15, 26, 0.6);
    z-index: 1000;
}

.vue-grid-layout,
.vue-grid-item,
.vue-grid-item.vue-grid-placeholder,
.vue-draggable-handle,
.disable-widgets-container,
.widgets-disabled {
    display: none !important;
}

.not-allowed-widgets-container{
    text-align: center;
    margin-top: 50px;
    padding: 20px;
    color: var(--color-text-primary);
}

.link-to-store{
    color: var(--primary) !important;
}

.not-allowed-widgets-title{
    color: var(--color-warning);
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 10px;
}

#main-container {
    color: var(--color-text-primary);
  }

  .container-image{
    margin: 0 auto;
    max-width: 800px;
    max-height: auto;
  }

  .container-image img{
    max-height: 800px;
    margin: 20px;
    border-radius: 5px;
    max-width: 100%;
    height: auto;
  }

  .text-centeR {
    text-align: center;
    margin: 20px;
  }

  .container-image p {    
    text-align: justify;
    color: var(--color-text-secondary);
  }

  .login-button{
    color: white;
    background-color: var(--primary);
    border-radius: 20px;
    cursor: pointer;
    padding: 10px 25px;
    text-decoration: none;
    border: none;
    outline: none;
    width: auto;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 600;
    transition: background-color 0.2s ease;
  }
  .login-button:hover{
    background-color: #0095c5;
}

@media (max-width: 768px) {
    .widget-grid {
        grid-template-columns: 1fr;
    }
    .help-pop-up {
        width: 95%;
    }
    .modal-body {
        padding: 15px;
    }
    .widget-list-item {
        padding: 10px;
    }
    }
</style>

