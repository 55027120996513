<template>
  <div class="widget-wrapper">
    <div class="widget-header">
      <h4 class="widget-title">{{ title }}</h4>
      <div class="widget-controls">
        <a v-if="gdUrl" class="widget-control-link" :href="gdUrl">GD</a>
        <span 
          v-if="hasHelp" 
          class="material-icons widget-control-icon" 
          @click="showHelp">
          help
        </span>
        <span 
          class="material-icons widget-control-icon" 
          @click="closeWidget">
          close
        </span>
      </div>
    </div>
    
    <div class="widget-content" :class="{ 'no-access': !hasAccess }">
      <slot v-if="hasAccess"></slot>
      <div v-else class="no-access-message">
        <p>Su plan actual no tiene acceso a esta herramienta.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WidgetWrapper',
  props: {
    title: {
      type: String,
      required: true
    },
    widgetId: {
      type: String,
      required: true
    },
    widgetName: {
      type: String,
      required: true
    },
    gdUrl: {
      type: String,
      default: ''
    },
    hasAccess: {
      type: Boolean,
      default: true
    },
    hasHelp: {
      type: Boolean,
      default: false
    },
    helpUrl: {
      type: String,
      default: ''
    },
    helpTitle: {
      type: String,
      default: ''
    }
  },
  methods: {
    closeWidget() {
      this.$emit('close-widget', { 
        id: this.widgetId, 
        name: this.widgetName 
      });
    },
    showHelp() {
      if (this.hasHelp) {
        this.$emit('show-help', {
          url: this.helpUrl,
          title: this.helpTitle
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.widget-wrapper {
  background-color: var(--color-surface, #F3F4F7);
  border-radius: 10px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(15, 15, 26, 0.08);
  display: flex;
  flex-direction: column;
  position: relative;
  
  &:hover {
    box-shadow: 0 4px 20px rgba(15, 15, 26, 0.12);
  }
}

.widget-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.08));
}

.widget-title {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: var(--primary, #00aedd);
}

.widget-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.widget-control-icon {
  color: var(--color-text-secondary, #606577);
  cursor: pointer;
  font-size: 20px;
  transition: color 0.2s ease;
  
  &:hover {
    color: var(--primary, #00aedd);
  }
}

.widget-control-link {
  color: var(--primary, #00aedd);
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  
  &:hover {
    text-decoration: underline;
  }
}

.widget-content {
  flex: 1;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  &.no-access {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--color-text-secondary, #606577);
    padding: 16px 20px;
  }
}

.no-access-message {
  font-size: 14px;
  line-height: 1.5;
  color: var(--color-text-secondary, #606577);
  max-width: 300px;
  margin: 0 auto;
  padding: 16px 20px;
}


@media (max-width: 767px) {
  .widget-header {
    padding: 12px 16px;
  }
  
  .widget-content {

    &.no-access {
       padding: 12px 16px;
    }
  }
}
</style> 