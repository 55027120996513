<template>
  <widget-wrapper
    title="Resumen de emisiones en directo"
    widget-id="widget-master-class-overview"
    widget-name="Resumen de emisiones en directo"
    :has-alternate-action="true"
    alternate-action-icon="launch"
    alternate-action-url="/app/videos"
    theme="dark"
    @close-widget="_closeWidget"
  >
    <!-- Loading State -->
    <div v-if="loading" class="widget-loading">
      <div class="spinner"></div>
    </div>
    
    <!-- Content -->
    <div v-else class="masterclass-content">
      <a :href="urlLink" target="_blank" class="masterclass-link">
        <img :src="overview_picture" alt="Resumen de emisiones" class="masterclass-image" />
      </a>
    </div>
  </widget-wrapper>
</template>

<script>
import APICaller from "@/components/codeComponents/APICaller.js";
import { mapGetters } from "vuex";
import WidgetWrapper from "@/components/widgets/WidgetWrapper.vue";

export default {
  mixins: [APICaller],
  name: "MasterclassOverview",
  components: {
    WidgetWrapper
  },
  data() {
    return {
      urlLink: "",
      overview_picture: "",
      loading: true,
      widget_name: {
        id: "widget-master-class-overview",
        name: "Resumen de emisiones en directo",
      },
    };
  },
  mounted() {
    this.urlLink = this.$detectEnvironment() + "/app/videos";
    this._getMasterclassGIF();
  },
  computed: {
    ...mapGetters(["is_mobile_device"]),
  },
  methods: {
    _getMasterclassGIF() {
      let success = (response) => {
        if (response.data.value == 0) {
          this.overview_picture =
            process.env.VUE_APP_API +
            "/sites/all/themes/Porto_sub/widgets/masterclass_overview/master_class_overview_seen.gif";
        } else {
          this.overview_picture =
            process.env.VUE_APP_API +
            "/sites/all/themes/Porto_sub/widgets/masterclass_overview/master_class_overview.gif";
        }
        this.loading = false;
      };

      let failure = () => {
        console.error("Failed to load masterclass GIF");
        this.loading = false;
      };

      let url = "/api/v1/widgets/masterclass-overview";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(
        "146",
        "Error cargando información de masterclass."
      );
      const apiCallConfig = { loadingOverlay: false };

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },

    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/widgets/WidgetStyles.scss";

.widget-loading {
  @extend .widget-loading-base;
  background-color: var(--color-background, #0F0F1A);
}

.spinner {
  @extend .loading-spinner;
}

.masterclass-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: var(--color-background, #0F0F1A);
}

.masterclass-link {
  display: block;
  width: 100%;
  height: 100%;
  transition: opacity 0.2s ease;
  
  &:hover {
    opacity: 0.9;
  }
}

.masterclass-image {
  width: 100%;
  height: 100%;
}

@media (max-width: 450px) {
  .masterclass-image {
    object-fit: cover;
  }
}
</style>
