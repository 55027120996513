<template>
  <widget-wrapper
    title="Citas célebres"
    widget-id="widget-motivational-sentences"
    widget-name="Citas célebres"
    theme="dark"
    @close-widget="_closeWidget"
  >
    <!-- Loading State -->
    <div v-if="loading" class="widget-loading">
      <div class="spinner"></div>
    </div>
    
    <!-- Content -->
    <div v-else class="motivational-content">
      <img 
        :src="'https://www.eurekers.com' + motivational_sentence" 
        alt="Cita motivacional"
        class="motivational-image"
      />
    </div>
  </widget-wrapper>
</template>

<script>
import { mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";
import WidgetWrapper from "@/components/widgets/WidgetWrapper.vue";

export default {
  mixins: [APICaller],
  name: "MotivationalSentences",
  components: {
    WidgetWrapper
  },
  data() {
    return {
      motivational_sentence: "",
      loading: true,
      widget_name: {
        id: "widget-motivational-sentences",
        name: "Citas célebres",
      },
    };
  },
  mounted() {
    this._getMotivationalSentences();
  },
  computed: {
    ...mapGetters(["is_mobile_device"]),
  },
  methods: {
    _getMotivationalSentences() {
      let success = (response) => {
        this.motivational_sentence = response.data;
        this.loading = false;
      };
      
      let failure = () => {
        console.error("Failed to load motivational sentence");
        this.loading = false;
      };

      let url = "/api/v1/widgets/get-motivational-sentence";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(
        "148",
        "Error recuperando frase motivacional."
      );
      const apiCallConfig = { loadingOverlay: false };

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },

    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/widgets/WidgetStyles.scss";

.widget-loading {
  @extend .widget-loading-base;
  background-color: var(--color-background, #0F0F1A);
}

.spinner {
  @extend .loading-spinner;
}

.motivational-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-background, #0F0F1A);
}

.motivational-image {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 450px) {
  .motivational-content {
    padding: 12px;
  }
  
  .motivational-image {
    width: 100%;
  }
}
</style>
