<template>
  <widget-wrapper
    title="Alarmas"
    widget-id="widget-alerts"
    widget-name="Alarmas"
    :has-help="true"
    :help-url="url_iframe"
    :help-title="help_tittle"
    :has-alternate-action="true"
    alternate-action-icon="launch"
    alternate-action-url="/app/graficador-dinamico"
    theme="dark"
    @close-widget="_closeWidget"
    @show-help="_sendDataToIframe"
  >
    <div v-if="loading" class="widget-loading">
      <div class="spinner"></div>
    </div>
    
    <div v-else class="alerts-content">
      <!-- Alert Tabs -->
      <div class="alert-tabs">
        <div 
          class="alert-tab" 
          :class="{ 'active': activeTab === 'pending' }"
          @click="activeTab = 'pending'"
        >
          Pendientes ({{ pending_alerts.length }})
        </div>
        <div 
          class="alert-tab" 
          :class="{ 'active': activeTab === 'executed' }"
          @click="activeTab = 'executed'"
        >
          Ejecutadas ({{ executed_alerts.length }})
        </div>
      </div>
      
      <!-- Pending Alerts -->
      <div v-if="activeTab === 'pending'" class="alerts-list">
        <div v-if="pending_alerts.length > 0">
          <div 
            v-for="alert in pending_alerts" 
            :key="alert.id" 
            class="alert-item"
          >
            <div class="alert-item-content">
              <div class="alert-ticker">{{ alert.ticker_name }}</div>
              <div class="alert-details">
                {{ alert.alert_field_string }} {{ alert.alert_mode_string }} {{ alert.alert_data }}
              </div>
            </div>
            <button @click="_deleteAlarm(alert.id)" class="delete-btn">
              <span class="material-icons">close</span>
            </button>
          </div>
        </div>
        <div v-else class="empty-alerts">
          <p>No hay alarmas pendientes</p>
        </div>
      </div>
      
      <!-- Executed Alerts -->
      <div v-if="activeTab === 'executed'" class="alerts-list">
        <div v-if="executed_alerts.length > 0">
          <div 
            v-for="alert in executed_alerts" 
            :key="alert.id" 
            class="alert-item executed"
          >
            <div class="alert-item-content">
              <div class="alert-ticker">{{ alert.ticker_name }}</div>
              <div class="alert-details">
                {{ alert.alert_field_string }} {{ alert.alert_mode_string }} {{ alert.alert_data }}
                <div class="alert-executed-date">{{ alert.executed_date || alert.modified }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="empty-alerts">
          <p>No hay alarmas ejecutadas</p>
        </div>
      </div>
    </div>
  </widget-wrapper>
</template>

<script>
import { mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";
import WidgetWrapper from "@/components/widgets/WidgetWrapper.vue";

export default {
  mixins: [APICaller],
  name: "Alarmas",
  components: {
    WidgetWrapper
  },
  data() {
    return {
      widget_name: { id: "widget-alerts", name: "Alarmas" },
      loading: true,
      alerts: [],
      activeTab: "pending",
      uid: "",
      url_iframe: "https://player.vimeo.com/video/305716774?title=0&byline=0&portrait=0&autoplay=1",
      help_tittle: "Ayuda sobre alertas",
    };
  },
  mounted() {
    this._loadData();
  },
  computed: {
    ...mapGetters(["is_mobile_device", "_g_User"]),
    
    pending_alerts() {
      return this.alerts.filter(alert => alert.status == "0");
    },
    
    executed_alerts() {
      return this.alerts.filter(alert => alert.status == "1");
    }
  },
  methods: {
    _loadData() {
      this.loading = true;
      if (this._g_User && this._g_User.uid) {
        this.uid = this._g_User.uid;
        this._getAlarms();
      } else {
        this.loading = false;
      }
    },
    
    _sendDataToIframe: function() {
      this.$emit("url_iframe", this.url_iframe);
      this.$emit("help_tittle", this.help_tittle);
      this.$emit("load_iframe");
    },

    _deleteAlarm: function(id) {
      this.loading = true;
      
      let params = new URLSearchParams();
      params.append("uid", this.uid);
      params.append("alert_id", id);

      let success = () => {
        this._getAlarms();
      };

      let failure = () => {
        this.loading = false;
      };

      let url = "/api/v1/eurekers-alerts/delete";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(failure, "309", "Error borrando alarma.");

      this._postAPICall(url, params, successHandler, failureHandler);
    },

    _getAlarms: function() {
      let params = new URLSearchParams();
      params.append("uid", this.uid);

      let success = (response) => {
        this.alerts = response.data || [];
        this.loading = false;
      };

      let failure = () => {
        this.alerts = [];
        this.loading = false;
      };

      let url = "/api/v1/eurekers-alerts/get-user-alerts";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(failure, "310", "Error recuperando alarmas.");

      this._postAPICall(url, params, successHandler, failureHandler);
    },

    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/widgets/WidgetStyles.scss";

.widget-loading {
  @extend .widget-loading-base;
  background-color: var(--color-background, #0F0F1A);
}

.spinner {
  @extend .loading-spinner;
}

.alerts-content {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.alert-tabs {
  display: flex;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  
  .alert-tab {
    flex: 1;
    text-align: center;
    padding: 8px 4px;
    font-size: 12px;
    color: var(--color-text-secondary, #BFC2CD);
    cursor: pointer;
    transition: all 0.2s ease;
    
    &.active {
      color: var(--primary, #00AEDD);
      border-bottom: 2px solid var(--primary, #00AEDD);
    }
    
    &:hover {
      background-color: rgba(255, 255, 255, 0.05);
    }
  }
}

.alerts-list {
  flex: 1;
  padding: 8px;
  overflow-y: auto;
}

.alert-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 8px;
  margin-bottom: 6px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  position: relative;
  border-left: 3px solid var(--primary, #00AEDD);
  
  &.executed {
    border-left-color: var(--color-success, #57A869);
  }
}

.alert-item-content {
  flex-grow: 1;
  overflow: hidden;
}

.alert-ticker {
  font-weight: 600;
  font-size: 12px;
  color: var(--color-text-primary, #FFFFFF);
  margin-bottom: 2px;
}

.alert-details {
  font-size: 11px;
  color: var(--color-text-secondary, #BFC2CD);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.alert-executed-date {
  font-size: 10px;
  color: var(--color-success, #57A869);
  margin-top: 2px;
}

.delete-btn {
  background: none;
  border: none;
  color: var(--color-text-secondary, #BFC2CD);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 0;
  transition: all 0.2s ease;
  flex-shrink: 0;
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: var(--color-text-primary, #FFFFFF);
  }
  
  .material-icons {
    font-size: 16px;
  }
}

.empty-alerts {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 10px;
  
  p {
    color: var(--color-text-secondary, #BFC2CD);
    font-size: 12px;
    font-style: italic;
    text-align: center;
  }
}
</style>
