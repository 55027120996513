<template>
  <widget-wrapper
    title="Inteligencia artificial"
    widget-id="widget-artificial-intelligence"
    widget-name="Inteligencia artificial"
    :has-help="true"
    :help-url="url_iframe"
    :help-title="help_tittle"
    :has-alternate-action="true"
    alternate-action-icon="launch"
    alternate-action-url="/app/artificial-inteligence"
    theme="dark"
    @close-widget="_closeWidget"
    @show-help="_sendDataToIframe"
  >
    <!-- Loading State -->
    <div v-if="loading" class="widget-loading">
      <div class="spinner"></div>
    </div>
    
    <!-- Content Area -->
    <div v-else class="ai-content">
      <!-- Test Button Section -->
      <div class="ai-test-button">
        <button class="primary-button" @click="_goToTest">
          <span class="material-icons">assessment</span>
          Hacer el test
        </button>
      </div>
      
      <!-- AI Results -->
      <div v-if="data" class="values-list-container">
        <div class="list-header">
          <span class="col-ticker">Ticker</span>
          <span class="col-entry">Entrada</span>
          <span class="col-compatibility">Compatibilidad</span>
        </div>
        <div class="values-list">
          <div 
            v-for="(value, index) in table_body" 
            :key="index"
            class="value-item"
            @click="openValueGD(value.Ticker)"
          >
            <span class="col-ticker">{{ value.Ticker }}</span>
            <span class="col-entry">{{ value.Entry }}</span>
            <span class="col-compatibility">{{ value.Compatibility }}</span>
          </div>
        </div>
      </div>
      
      <!-- No Data Message -->
      <div v-else class="no-data">
        <p>Necesitas completar el test para poder ver resultados en esta herramienta.</p>
      </div>
    </div>
  </widget-wrapper>
</template>

<script>
import { mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";
import WidgetWrapper from "@/components/widgets/WidgetWrapper.vue";

export default {
  mixins: [APICaller],
  name: "ArtificialIntelligence",
  components: {
    WidgetWrapper
  },
  data() {
    return {
      gd_url: "",
      table_header: ["Ticker", "Entry", "Compatibility"],
      table_body: [],
      loading: true,
      widget_name: {
        id: "widget-artificial-intelligence",
        name: "Inteligencia artificial",
      },
      data: false,
      url_iframe: "https://player.vimeo.com/video/211289739?title=0&byline=0&portrait=0&autoplay=1",
      help_tittle: "Ayuda sobre inteligencia artificial",
    };
  },
  mounted() {
    this.gd_url = this.$detectEnvironment() + "/app/graficador-dinamico/compatibility";
    this._getAI();
  },
  computed: {
    ...mapGetters(["is_mobile_device"]),
  },
  methods: {
    _sendDataToIframe: function() {
      this.$emit("url_iframe", this.url_iframe);
      this.$emit("help_tittle", this.help_tittle);
      this.$emit("load_iframe");
    },
    
    _getAI() {
      this.loading = true;
      
      let success = (response) => {
        var ia = response.data;
        if (Array.isArray(ia)) {
          ia.splice(0, 1);
        }
        
        if (ia && ia.length > 0) {
          this.data = true;
          Object.entries(ia).forEach((e) => {
            let obj = {};
            if (e[1].stock && e[1].tipus_entrada) {
              obj["Ticker"] = e[1].stock["ticker"];
              obj["Entry"] = e[1].tipus_entrada;
              obj["Compatibility"] = e[1].compatibility;
              this.table_body.push(obj);
            }
          });
        }
        
        this.loading = false;
      };

      let failure = () => {
        this.loading = false;
        this.data = false;
      };

      let url = "/api/v1/widgets/get-artificial-intelligence";
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(failure, "138", "Error cargando inteligencia artificial.");
      const apiCallConfig = { loadingOverlay: false };

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },

    _goToTest() {
      window.open(process.env.VUE_APP_API + "/test-graficos-potenciales/start");
    },

    openValueGD(ticker) {
      window.open(this.$detectEnvironment() + "/app/graficador-dinamico/" + ticker, "_blank");
    },

    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/widgets/WidgetStyles.scss";

.widget-loading {
  @extend .widget-loading-base;
  background-color: var(--color-background, #0F0F1A);
}

.spinner {
  @extend .loading-spinner;
}

.ai-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.ai-test-button {
  padding: 12px 16px;
  border-bottom: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
}

.primary-button {
  width: 100%;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: var(--primary, #00aedd);
  color: #FFFFFF;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: rgba(0, 174, 221, 0.8);
  }
  
  .material-icons {
    font-size: 18px;
  }
}

.values-list-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.list-header {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 600;
  color: var(--color-text-secondary, #BFC2CD);
  border-bottom: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
  background-color: var(--color-surface, #20202B);
  flex-shrink: 0;
}

.values-list {
  flex: 1;
  overflow-y: auto;
  padding: 8px 16px;
}

.value-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  font-size: 13px;
  border-bottom: 1px solid var(--color-outline, rgba(255, 255, 255, 0.1));
  cursor: pointer;
  transition: background-color 0.15s ease;
  
  &:hover {
    background-color: var(--color-active-surface, #193141);
  }
  
  &:last-child {
    border-bottom: none;
  }
}

// Column widths and colors
.col-ticker { 
  width: 30%; 
  font-weight: 500; 
  color: var(--color-text-primary, #FFFFFF); 
}

.col-entry { 
  width: 40%; 
  text-align: center; 
  color: var(--color-text-secondary, #BFC2CD); 
}

.col-compatibility { 
  width: 30%; 
  text-align: right; 
  font-weight: 600; 
  color: var(--primary, #00aedd); 
}

.no-data {
  @extend .empty-state;
  background-color: var(--color-background, #0F0F1A);
  
  p {
    color: var(--color-text-secondary, #BFC2CD);
    font-size: 14px;
  }
}
</style>
