<template>
  <widget-wrapper
    title="Valores en espera"
    widget-id="widget-portfolio-waiting-values"
    widget-name="Valores en espera"
    :has-help="true"
    :help-url="url_iframe"
    :help-title="help_tittle"
    theme="dark"
    :has-alternate-action="true"
    alternate-action-icon="launch"
    alternate-action-url="/app/portfolio"
    @close-widget="_closeWidget"
    @show-help="_sendDataToIframe"
  >
    <!-- Loading State -->
    <div v-if="loading" class="widget-loading">
      <div class="spinner"></div>
    </div>
    
    <!-- Content Area -->
    <div v-else class="waiting-values-content">
      <!-- Portfolio Selector -->
      <div v-if="portfolio_list.length > 0" class="portfolio-selector">
        <select v-model="value_selected" @change="_selectPortfolio">
          <option v-if="!value_selected" value="" disabled>Selecciona cartera</option>
          <option 
            v-for="portfolio in portfolio_list"
            :key="portfolio.value"
            :value="portfolio.value"
          >
            {{ portfolio.name }}
          </option>
        </select>
      </div>
      <div v-else class="no-portfolios">
        No hay carteras disponibles.
      </div>
      
      <!-- Values List -->
      <div v-if="there_are_data && value_selected" class="values-list-container">
        <div class="list-header">
          <span class="col-ticker">Ticker</span>
          <span class="col-entry">Entrada</span>
          <span class="col-risk">Riesgo</span>
        </div>
        <div class="values-list">
          <div 
            v-for="(value, index) in waiting_values" 
            :key="index"
            class="value-item"
            @click="openValueGD(value.ticker)"
          >
            <span class="col-ticker">{{ value.ticker }}</span>
            <span class="col-entry">{{ value.entry }}</span>
            <span class="col-risk">{{ value.risk }}%</span>
          </div>
        </div>
      </div>
      
      <!-- No Data Message -->
      <div v-else-if="value_selected" class="no-data">
        <p>No tienes valores en espera en esta cartera.</p>
      </div>
    </div>
  </widget-wrapper>
</template>

<script>
import GetPortfolios from "@/components/codeComponents/getPortfolios.js";
import APICaller from "@/components/codeComponents/APICaller.js";
import { mapGetters } from "vuex";
import WidgetWrapper from "@/components/widgets/WidgetWrapper.vue";

export default {
  mixins: [APICaller, GetPortfolios],
  components: {
    WidgetWrapper
  },
  name: "WaitingValues",
  data() {
    return {
      value_selected: "", // Stores the ID of the selected portfolio
      waiting_values: [],
      widget_name: {
        id: "widget-portfolio-waiting-values",
        name: "Valores en espera",
      },
      there_are_data: false,
      portfolio_list: [], // Format: [{ name: 'Portfolio Name', value: 'portfolio_id' }]
      loading: true,
      url_iframe: "https://player.vimeo.com/video/187303863?title=0&byline=0&portrait=0&autoplay=1",
      help_tittle: "Ayuda sobre valores en espera",
    };
  },
  created() {
    // Start the loading sequence
    this._loadData();
  },
  computed: {
    ...mapGetters(["is_mobile_device"]),
  },
  methods: {
    _sendDataToIframe: function() {
      this.$emit("url_iframe", this.url_iframe);
      this.$emit("help_tittle", this.help_tittle);
      this.$emit("load_iframe");
    },
    
    // Sequential loading function
    _loadData() {
      this.loading = true;
      this._getPortfolios();
    },
    
    // Fetch portfolios using the mixin
    _getPortfolios() {
      let success = (response) => {
        if (response.data && response.data.length > 0) {
          this.portfolio_list = response.data.map(p => ({
            name: p.name,
            value: p.idc
          }));
          // After portfolios are loaded, get the default portfolio
          this._getDefaultPortfolio();
        } else {
          this.portfolio_list = [];
          this.loading = false;
        }
      };
      
      let failure = () => {
        console.error("Failed to load portfolios");
        this.portfolio_list = [];
        this.loading = false;
      };

      let url = "/api/v1/portfolio/list";
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(failure, "ERR-PORTF", "Error fetching portfolios.");
      this._getAPICall(url, successHandler, failureHandler, { loadingOverlay: false });
    },
    
    // Get default portfolio
    _getDefaultPortfolio() {
      // If no portfolios were loaded, no need to get a default
      if (this.portfolio_list.length === 0) {
        this.loading = false;
        return;
      }
      
      let success = (response) => {
        if (response.data && response.data.idc) {
          // Set the selected portfolio to the default from preferences
          this.value_selected = response.data.idc;
        } else {
          // If no default set, use the first portfolio
          this.value_selected = this.portfolio_list[0].value;
        }
        
        // Now that we have a selected portfolio, load its waiting values
        this._getWaitingValues();
      };
      
      let failure = () => {
        console.error("Failed to load default portfolio");
        // On error, fall back to the first portfolio
        this.value_selected = this.portfolio_list[0].value;
        this._getWaitingValues();
      };

      let url = "/api/v1/portfolio-invest-preferences";
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(failure, "162", "Error recuperando preferencias de inversión.");
      this._getAPICall(url, successHandler, failureHandler, { loadingOverlay: false });
    },

    _getWaitingValues() {
      if (!this.value_selected) {
        this.loading = false;
        return;
      }
      
      this.loading = true;
      this.waiting_values = [];
      this.there_are_data = false;

      let success = (response) => {
        const data = response.data || [];
        
        if (data.length > 0) {
          this.there_are_data = true;
          this.waiting_values = data.map(item => ({
            ticker: item.ticker,
            entry: item.entry_type_name,
            risk: item.risk
          }));
        } else {
          this.there_are_data = false;
        }
        
        this.loading = false;
      };

      let failure = () => {
        console.error("Failed to load waiting values");
        this.there_are_data = false;
        this.loading = false;
      };

      let url = "/api/v1/portfolio/waitings/" + this.value_selected;
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(failure, "161", "Error recuperando valores en espera.");
      const apiCallConfig = { loadingOverlay: false };

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },
    
    _selectPortfolio() {
      this._getWaitingValues();
    },

    openValueGD(ticker) {
      window.open(this.$detectEnvironment() + "/app/graficador-dinamico/" + ticker, "_blank");
    },

    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/widgets/WidgetStyles.scss";

.widget-loading {
  @extend .widget-loading-base;
  background-color: var(--color-background, #0F0F1A);
}

.spinner {
  @extend .loading-spinner;
}

.waiting-values-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.portfolio-selector {
  padding: 12px 16px;
  border-bottom: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
  flex-shrink: 0;
  
  select {
    width: 100%;
    padding: 10px 12px;
    border: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
    border-radius: 6px;
    background-color: var(--color-surface, #20202B);
    color: var(--color-text-primary, #FFFFFF);
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
    appearance: none; /* Remove default arrow */
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M2.5 4L6 7.5L9.5 4' stroke='%23BFC2CD' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 12px;
    
    &:focus {
      outline: none;
      border-color: var(--primary, #00aedd);
      box-shadow: 0 0 0 1px var(--primary, #00aedd);
    }

    &:hover {
      border-color: var(--primary, #00aedd);
    }
    
    option {
      background-color: var(--color-background, #0F0F1A);
      color: var(--color-text-primary, #FFFFFF);
      padding: 10px;
    }
  }
}

.no-portfolios {
   padding: 12px 16px;
   color: var(--color-text-secondary, #BFC2CD);
   font-size: 14px;
   text-align: center;
   font-style: italic;
}

.values-list-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.list-header {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 600;
  color: var(--color-text-secondary, #BFC2CD);
  border-bottom: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
  background-color: var(--color-surface, #20202B);
  flex-shrink: 0;
}

.values-list {
  flex: 1;
  overflow-y: auto;
  padding: 8px 16px;
}

.value-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  font-size: 13px;
  border-bottom: 1px solid var(--color-outline, rgba(255, 255, 255, 0.1));
  cursor: pointer;
  transition: background-color 0.15s ease;
  
  &:hover {
    background-color: var(--color-active-surface, #193141);
  }
  
  &:last-child {
    border-bottom: none;
  }
}

// Column widths and colors
.col-ticker { 
  width: 40%; 
  font-weight: 500; 
  color: var(--color-text-primary, #FFFFFF); 
}

.col-entry { 
  width: 35%; 
  text-align: center; 
  color: var(--color-text-secondary, #BFC2CD); 
}

.col-risk { 
  width: 20%; 
  text-align: right; 
  font-weight: 600; 
  color: var(--primary, #00aedd); 
}

.no-data {
  @extend .empty-state;
  
  p {
    color: var(--color-text-secondary, #BFC2CD);
    font-size: 14px;
  }
}
</style>
