<template>
  <widget-wrapper
    title="Almas gemelas"
    widget-id="widget-twin-souls"
    widget-name="Almas gemelas"
    :gd-url="gd_url + activeTwinIndex"
    :has-help="true"
    :help-url="url_iframe"
    :help-title="help_tittle"
    @close-widget="_closeWidget"
    @show-help="_sendDataToIframe"
  >
    <!-- Loading State -->
    <div v-if="loading" class="widget-loading">
      <div class="spinner"></div>
    </div>
    
    <!-- Content When Data is Available -->
    <div v-else-if="there_are_data" class="twin-content">
      <div class="twin-souls-selector">
        <button 
          v-for="(twin, index) in twin_souls" 
          :key="index"
          class="twin-button"
          :class="{ 'active': activeTwinIndex === index }"
          @click="selectTwin(index)"
        >
          <span class="material-icons">person</span>
        </button>
      </div>
      
      <div class="twin-details">
        <div v-if="currentTwin" class="twin-info">
          <div class="info-row">
            <span class="info-label">Desde:</span>
            <span class="info-value">{{ currentTwin.extra_info.eurekers_since }}</span>
          </div>
          <div class="info-row">
            <span class="info-label">Ciudad:</span>
            <span class="info-value">{{ currentTwin.extra_info.city }}</span>
          </div>
        </div>
      </div>
      
      <div class="twin-tickers">
        <div class="table-header">Tickers recomendados</div>
        <div class="ticker-chips">
          <div 
            v-for="(ticker, index) in tickers" 
            :key="index"
            class="ticker-chip"
            @click="openTickerGD(ticker)"
          >
            {{ ticker }}
          </div>
          <div v-if="tickers.length === 0" class="empty-tickers">
            No hay tickers disponibles
          </div>
        </div>
      </div>
    </div>
    
    <!-- No Data Message -->
    <div v-else class="no-data">
      <p>Necesitas tener tickers en Carteras o en Favoritos para ver resultados.</p>
    </div>
  </widget-wrapper>
</template>

<script>
import APICaller from "@/components/codeComponents/APICaller.js";
import { mapGetters } from "vuex";
import WidgetWrapper from "@/components/widgets/WidgetWrapper.vue";

export default {
  mixins: [APICaller],
  components: {
    WidgetWrapper
  },
  name: "TwinSouls",
  data() {
    return {
      twin_souls: [],
      activeTwinIndex: 0,
      loading: true,
      there_are_data: false,
      gd_url: "",
      url_iframe: "https://player.vimeo.com/video/211289703?title=0&byline=0&portrait=0&autoplay=1",
      help_tittle: "Ayuda sobre almas gemelas",
      widget_name: { id: "widget-twin-souls", name: "Almas gemelas" }
    };
  },
  computed: {
    currentTwin() {
      return this.twin_souls[this.activeTwinIndex] || null;
    },
    tickers() {
      if (!this.currentTwin || !this.currentTwin.tickers) {
        return [];
      }
      return this.currentTwin.tickers.map(t => t.toString());
    }
  },
  mounted() {
    this.gd_url = this.$detectEnvironment() + "/app/graficador-dinamico/twin-souls/";
    this._getTwinSouls();
  },
  methods: {
    _getTwinSouls() {
      this.loading = true;
      
      let success = (response) => {
        this.twin_souls = response.data || [];
        this.there_are_data = this.twin_souls.length > 0;
        this.loading = false;
        
        if (this.there_are_data) {
          this.activeTwinIndex = 0;
        }
      };
      
      let failure = () => {
        this.loading = false;
        this.there_are_data = false;
      };

      let url = "/api/v1/widgets/get-twin-souls";
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(failure, "160", "Error recuperando almas gemelas.");
      const apiCallConfig = { loadingOverlay: false };

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },
    
    selectTwin(index) {
      this.activeTwinIndex = index;
    },
    
    _sendDataToIframe: function() {
      this.$emit("url_iframe", this.url_iframe);
      this.$emit("help_tittle", this.help_tittle);
      this.$emit("load_iframe");
    },
    
    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },
    
    openTickerGD(ticker) {
      window.open(this.$detectEnvironment() + "/app/graficador-dinamico/" + ticker, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/components/widgets/WidgetStyles.scss";

.widget-loading {
  @extend .widget-loading-base;
  background-color: var(--color-background, #0F0F1A);
}

.spinner {
  @extend .loading-spinner;
}

.no-data {
  @extend .empty-state;
  
  p {
    color: var(--color-text-secondary, #606577);
    font-size: 14px;
    text-align: center;
    padding: 0 20px;
    max-width: 300px;
  }
}

.twin-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.twin-souls-selector {
  display: flex;
  justify-content: center;
  gap: 8px;
  padding: 10px 0;
  border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.08));
}

.twin-button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.08));
  color: var(--color-text-secondary, #606577);
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 0;
  
  &:hover, &.active {
    background-color: var(--primary, #00aedd);
    border-color: var(--primary, #00aedd);
    color: white;
  }
  
  .material-icons {
    font-size: 18px;
  }
}

.twin-details {
  padding: 10px 16px;
  border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.08));
}

.twin-info {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 16px;
}

.info-row {
  font-size: 12px;
  min-width: 120px;
}

.info-label {
  color: var(--color-text-secondary, #606577);
  font-weight: 500;
  margin-right: 4px;
}

.info-value {
  color: var(--color-text-primary, #0f0f1a);
}

.twin-tickers {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.table-header {
  padding: 8px 16px;
  font-size: 13px;
  font-weight: 500;
  color: var(--color-text-secondary, #606577);
}

.ticker-chips {
  flex: 1;
  padding: 0 12px 10px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 8px;
}

.ticker-chip {
  font-size: 12px;
  background-color: rgba(0, 174, 221, 0.08);
  color: var(--primary, #00aedd);
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.15s ease;
  
  &:hover {
    background-color: var(--primary, #00aedd);
    color: white;
  }
}

.empty-tickers {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  color: var(--color-text-secondary, #606577);
  font-size: 13px;
  font-style: italic;
}
</style>
