<template>
  <widget-wrapper
    title="Búsquedas favoritas"
    widget-id="widget-favorite-search"
    widget-name="Búsquedas favoritas"
    :has-help="true"
    :help-url="url_iframe"
    :help-title="help_tittle"
    theme="dark"
    @close-widget="_closeWidget"
    @show-help="_sendDataToIframe"
  >
    <div v-if="loading" class="widget-loading">
      <div class="spinner"></div>
    </div>
    
    <div v-else class="favorite-search-content">
      <div class="search-selector">
        <label>{{ label_name }}</label>
        <select 
          class="modern-select" 
          v-model="selected_search"
          @change="handleSearchChange"
        >
          <option 
            v-for="option in options" 
            :key="option.name" 
            :value="option.name"
          >
            {{ option.value }}
          </option>
        </select>
      </div>
      
      <div class="action-buttons">
        <button 
          class="action-button"
          @click="_goToStockFinder()"
        >
          Buscar en el LISTADO DE VALORES
        </button>
        
        <button 
          class="action-button"
          @click="_goToDynamicStockChart()"
          :disabled="!selected_search"
        >
          Buscar en el GRAFICADOR DINÁMICO
        </button>
      </div>
    </div>
  </widget-wrapper>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";
import WidgetWrapper from "@/components/widgets/WidgetWrapper.vue";

export default {
  mixins: [APICaller],
  name: "FavoriteSearch",
  components: {
    WidgetWrapper
  },
  data() {
    return {
      loading: true,
      label_name: "Selecciona una búsqueda",
      options: [],
      selected_search: "",
      widget_name: { id: "widget-favorite-search", name: "Búsquedas favoritas" },
      url_iframe: "https://player.vimeo.com/video/187302851?title=0&byline=0&portrait=0&autoplay=1",
      help_tittle: "Ayuda sobre búsquedas favoritas",
    };
  },
  created() {
    this._getSearchOptions();
  },
  computed: {
    ...mapGetters(["is_mobile_device"]),
  },
  methods: {
    _sendDataToIframe() {
      this.$emit("url_iframe", this.url_iframe);
      this.$emit("help_tittle", this.help_tittle);
      this.$emit("load_iframe");
    },

    handleSearchChange() {
      // No additional action needed as we already have the selected value
    },

    _goToStockFinder() {
      setTimeout(() => {
        this._changeFinder();
      }, 100);
      this.$router.push("/app/graficador-dinamico/?mode=list");
    },
    
    _goToDynamicStockChart() {
      if (this.selected_search) {
        setTimeout(() => {
          this._changeFinder();
        }, 100);
        this.$router.push("/app/graficador-dinamico/?=mode=gd");
      }
    },
    
    _getSearchOptions() {
      let success = (response) => {
        const data = response.data;
        const obj_keys = Object.keys(data);
        const obj_values = Object.values(data);
        
        this.options = [];
        
        for (let i = 0; i < obj_keys.length; i++) {
          if (obj_keys[i] !== "last_search") {
            this.options.push({
              name: obj_keys[i],
              value: obj_values[i],
            });
          }
        }
        
        // Set default selected option to first item if available
        if (this.options.length > 0) {
          this.selected_search = this.options[0].name;
        }
        
        this.loading = false;
      };

      let failure = () => {
        this.loading = false;
      };

      let url = "/api/v1/widgets/get-favorite-search-options";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(failure, "142", "Error cargando opciones de búsqueda favoritas.");
      const apiCallConfig = { loadingOverlay: false };

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },

    ...mapMutations(["setRechargeState"]),

    _changeFinder() {
      let success = () => {
        this.setRechargeState();
      };

      let failure = () => {
        // Handle failure silently
      };

      let url = "/api/v1/stock-finder/change-finder/" + this.selected_search;

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(failure, "143", "Error cambiando la búsqueda.");
      const apiCallConfig = { loadingOverlay: false };

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },

    _closeWidget() {
      this.$emit("widget_name", this.widget_name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/widgets/WidgetStyles.scss";

.widget-loading {
  @extend .widget-loading-base;
  background-color: var(--color-background, #0F0F1A);
}

.spinner {
  @extend .loading-spinner;
}

.favorite-search-content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
}

.search-selector {
  display: flex;
  flex-direction: column;
  gap: 8px;
  
  label {
    font-size: 12px;
    font-weight: 500;
    color: var(--color-text-secondary, #BFC2CD);
  }
  
  .modern-select {
    height: 40px;
    background-color: var(--color-field-bg, rgba(255, 255, 255, 0.07));
    border: 1px solid var(--color-outline, rgba(255, 255, 255, 0.2));
    border-radius: 4px;
    padding: 0 12px;
    color: var(--color-text-primary, #FFFFFF);
    font-size: 14px;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23FFFFFF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 16px;
    transition: border-color 0.2s ease;
    
    &:hover, &:focus {
      border-color: var(--primary, #00aedd);
      outline: none;
    }
    
    option {
      background-color: var(--color-surface, #20202B);
      color: var(--color-text-primary, #FFFFFF);
    }
  }
}

.action-buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  
  .action-button {
    height: 40px;
    background-color: var(--primary, #00aedd);
    color: #FFFFFF;
    border: none;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
    
    &:hover {
      background-color: var(--primary-hover, #0091c4);
    }
    
    &:active {
      background-color: var(--primary-active, #0080b0);
    }
    
    &:disabled {
      background-color: rgba(255, 255, 255, 0.1);
      color: var(--color-text-inactive, rgba(255, 255, 255, 0.6));
      cursor: not-allowed;
    }
  }
}
</style>

