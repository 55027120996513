<template>
  <widget-wrapper
    title="Mejores valores alcistas"
    widget-id="widget-best-bullish-values"
    widget-name="Mejores valores alcistas"
    :gd-url="gd_url + value_selected"
    :has-help="true"
    :help-url="url_iframe"
    :help-title="help_tittle"
    theme="dark"
    @close-widget="_closeWidget"
    @show-help="_sendDataToIframe"
  >
    <div v-if="loading" class="widget-loading">
      <div class="spinner"></div>
    </div>
    
    <div v-else class="bullish-values-content">
      <!-- Modern Filter Tabs -->
      <div class="filter-tabs">
        <button 
          v-for="option in options" 
          :key="option.name"
          class="tab-button" 
          :class="{ active: value_selected === option.name }"
          @click="selectTimeframe(option.name)"
        >
          {{ option.value }}
        </button>
      </div>

      <!-- Modern Table -->
      <div class="modern-table-container">
        <table class="modern-table">
          <thead>
            <tr>
              <th>{{ table_header[0] }}</th>
              <th class="text-right">{{ table_header[1] }}</th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="(item, index) in table_body" 
              :key="index"
              @click="openTickerGD(item.ticker)"
            >
              <td>{{ item.ticker }}</td>
              <td>{{ item.comentario }}</td>
            </tr>
            <tr v-if="table_body.length === 0" class="empty-row">
              <td colspan="2">No hay valores alcistas en este periodo</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </widget-wrapper>
</template>

<script>
import APICaller from "@/components/codeComponents/APICaller.js";
import WidgetWrapper from "@/components/widgets/WidgetWrapper.vue";
import { mapGetters } from "vuex";

export default {
  mixins: [APICaller],
  name: "BestBullishValues",
  components: {
    WidgetWrapper
  },
  data() {
    return {
      gd_url: "",
      options: [
        { name: "last_month", value: "Último mes" },
        { name: "last_three_months", value: "Últimos 3 meses" },
        { name: "last_year", value: "Último año" },
      ],
      value_selected: "last_year",
      table_header: ["Ticker", "Rentabilidad"],
      table_body: [],
      widget_name: {
        id: "widget-best-bullish-values",
        name: "Mejores valores alcistas",
      },
      loading: true,
      url_iframe: "https://player.vimeo.com/video/261815307?title=0&byline=0&portrait=0&autoplay=1",
      help_tittle: "Ayuda sobre mejores valores alcistas",
    };
  },
  mounted() {
    this.gd_url = this.$detectEnvironment() + "/app/graficador-dinamico/best-bullish-values/";
    this._getBestBullish();
  },
  computed: {
    ...mapGetters(["is_mobile_device", "_g_User"]),
  },
  methods: {
    _sendDataToIframe: function() {
      this.$emit("url_iframe", this.url_iframe);
      this.$emit("help_tittle", this.help_tittle);
      this.$emit("load_iframe");
    },

    _getBestBullish() {
      this.loading = true;
      this.table_body = [];
      
      let success = (response) => {
        let datos = response.data;
        Object.entries(datos).forEach((e) => {
          let obj = {};
          obj["ticker"] = e[1].ticker;
          obj["comentario"] = e[1].profitability_interval + " %";
          this.table_body.push(obj);
        });
        this.loading = false;
      };

      let failure = () => {
        this.loading = false;
      };

      let url = "/api/v1/stock-finder/best-bullish-values/" + this.value_selected;

      if (this.value_selected && this.value_selected != "") {
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler(failure, "164", "Error recuperando valores alcistas.");
        const apiCallConfig = { loadingOverlay: false };

        this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
      }
    },

    selectTimeframe(value) {
      this.value_selected = value;
      this._getBestBullish();
    },
    
    openTickerGD(ticker) {
      if (ticker) {
        window.open(this.$detectEnvironment() + "/app/graficador-dinamico/" + ticker, "_blank");
      }
    },

    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/widgets/WidgetStyles.scss";

.widget-loading {
  @extend .widget-loading-base;
  background-color: var(--color-background, #0F0F1A);
}

.spinner {
  @extend .loading-spinner;
}

.bullish-values-content {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
}

.filter-tabs {
  display: flex;
  gap: 8px;
  border-bottom: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
  padding-bottom: 8px;
  justify-content: space-around;
  
  .tab-button {
    background: none;
    border: none;
    color: var(--color-text-inactive, rgba(255, 255, 255, 0.6));
    font-size: 12px;
    padding: 6px 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
    
    &:hover {
      color: var(--color-text-primary, #FFFFFF);
      background-color: var(--color-active-surface, #193141);
    }
    
    &.active {
      color: var(--color-text-primary, #FFFFFF);
      background-color: var(--color-active-surface, #193141);
      font-weight: 500;
    }
  }
}

.modern-table-container {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 20px;
}

.modern-table {
  width: 100%;
  border-collapse: collapse;
  
  th {
    text-align: left;
    padding: 10px;
    font-size: 12px;
    font-weight: 600;
    color: var(--color-text-secondary, #BFC2CD);
    border-bottom: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
    position: sticky;
    top: 0;
    background-color: var(--color-surface, #20202B);
  }
  
  td {
    padding: 10px;
    font-size: 13px;
    color: var(--color-text-primary, #FFFFFF);
    border-bottom: 1px solid var(--color-outline, rgba(255, 255, 255, 0.1));
  }
  
  tr {
    cursor: pointer;
    transition: background-color 0.15s ease;
    
    &:hover {
      background-color: var(--color-active-surface, #193141);
    }
    
    &:last-child td {
      border-bottom: none;
    }
  }
  
  td:first-child {
    font-weight: 500;
  }
  
  td:last-child {
    text-align: right;
    color: var(--primary, #00aedd);
    font-weight: 600;
  }
  
  .empty-row td {
    text-align: center;
    color: var(--color-text-secondary, #BFC2CD);
    font-style: italic;
    padding: 20px 10px;
  }
}

.text-right {
  text-align: right !important;
}
</style>
