<template>
  <widget-wrapper
    title="Buscar por ticker"
    widget-id="widget-ticker-search"
    widget-name="Búsqueda de ticker"
    theme="light"
    @close-widget="_closeWidget"
  >
    <!-- Content Area -->
    <div v-if="_g_UserFeatures.permissions.stock_rastreator" class="ticker-search-content">
      <div class="autocomplete-wrapper">
        <AutocompleteInput
          :options="autocompleteOptions"
          :show="showAutocomplete"
          :disabled="disabledAutocomplete"
          :autofocus="false"
          @select="_preprocessTicker"
          @suggestions="_handleSuggestions"
          placeholder="Buscar por ticker..."
        >
          <template slot="item" scope="option">
            <article class="autocomplete-item">
              <div class="autocomplete-item-content">
                <p class="autocomplete-item-title">{{ option.title }}</p>
                <p class="autocomplete-item-description">{{ option.description }}</p>
              </div>
            </article>
          </template>
        </AutocompleteInput>
      </div>
    </div>
    <div v-else class="no-access"> 
      <p>Su plan no cuenta con acceso a este widget</p>
    </div>
  </widget-wrapper>
</template>

<script>
import { mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";
import AutocompleteInput from "@/components/helpers/AutocompleteInput.vue";
import WidgetWrapper from "@/components/widgets/WidgetWrapper.vue";

export default {
  mixins: [APICaller],
  name: "TickerSearch",
  components: {
    AutocompleteInput,
    WidgetWrapper
  },
  data() {
    return {
      widget_name: { id: "widget-ticker-search", name: "Búsqueda de ticker" },
      ticker_selected: "",
      url_to_redirect: "",

      autocompleteOptions: [],
      showAutocomplete: true,
      disabledAutocomplete: false,
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters(["is_mobile_device", "_g_UserFeatures"]),
  },
  methods: {
    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },

    _handleSuggestions: function(keyword) {
      if (keyword && keyword.length > 0) {
        this._getAutocomplete(keyword);
      } else {
        this.autocompleteOptions = [];
      }
    },

    _getAutocomplete: function(keyword) {
      let success = (response) => {
        this.autocompleteOptions = Object.keys(response.data).map(key => ({
          title: key,
          description: response.data[key]
        }));
      };

      let url = "/blocks/autocomplete/" + keyword;

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(
        "159",
        "Error recuperando autocompletado."
      );
      const apiCallConfig = { loadingOverlay: false };

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },

    _preprocessTicker: function(selectedOption) {
      if (selectedOption && selectedOption.title) {
        let array = selectedOption.title.split(" ");
        this.ticker_selected = array[0];
        this.$router.push(
          "/app/graficador-dinamico/ticker/" +
            this.ticker_selected.replace("&", "_and_")
        );
      } else {
        console.error("Invalid option selected from autocomplete:", selectedOption);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/widgets/WidgetStyles.scss";

.ticker-search-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.autocomplete-wrapper {
  width: 100%;
}

.autocomplete-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  width: 100%;
}

.autocomplete-item-content {
  flex: 1;
  min-width: 0;
  overflow: hidden;
}

.autocomplete-item-title {
  font-weight: 600;
  font-size: 14px;
  color: var(--color-text-primary, #0F0F1A);
  margin: 0 0 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.autocomplete-item-description {
  font-size: 12px;
  color: var(--color-text-secondary, #606577);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-access {
  @extend .empty-state;
  padding: 20px;
  text-align: center;
  color: var(--color-warning, #FF9B00);
  font-size: 14px;
}

 ::v-deep .options-list {
  max-height: 225px !important;
}
</style>
