<template>
  <widget-wrapper
    title="Mejores valores platino"
    widget-id="widget-platinum-values"
    widget-name="Valores Platino"
    :gd-url="platinum_url"
    :has-help="false"
    :has-access="_g_UserFeatures.permissions.platinum_values_widget"
    @close-widget="_closeWidget"
  >
    <div v-if="loading" class="widget-loading">
      <div class="spinner"></div>
    </div>
      
    <div v-else class="platinum-content">
      <!-- Simple Region Tabs -->
      <div class="region-tabs">
        <button 
          v-for="(tab, index) in tabs" 
          :key="tab.id"
          class="tab-button" 
          :class="{ 'active': activeTab === index }"
          @click="activeTab = index"
        >
          {{ tab.name }}
        </button>
      </div>
      
      <!-- Value List for Current Tab -->
      <div class="values-container">
        <div v-if="getCurrentTabValues.length === 0" class="empty-list">
          No hay valores disponibles para esta región.
        </div>
        
        <div v-else class="values-compact-list">
          <div 
            v-for="(item, index) in getCurrentTabValues" 
            :key="index"
            class="value-item"
            @click="openValueGD(item.ticker)"
          >
            <span class="value-ticker">{{ item.ticker }}</span>
            <span class="value-score">{{ (item.platinum_compatibility * 100).toFixed(0) }}</span>
          </div>
        </div>
      </div>
    </div>
  </widget-wrapper>
</template>

<script>
import { mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";
import WidgetWrapper from "@/components/widgets/WidgetWrapper.vue";

export default {
  mixins: [APICaller],
  components: {
    WidgetWrapper
  },
  name: "PlatiniumBestValues",
  data() {
    return {
      platinum_url: "",
      best_values_eur: [],
      best_values_usd: [],
      best_values_other: [],
      loading: true,
      activeTab: 0,
      tabs: [
        { id: 'europe', name: 'Europa' },
        { id: 'usa', name: 'USA' },
        { id: 'other', name: 'Otros' }
      ],
      widget_name: { id: "widget-platinum-values", name: "Valores Platino" }
    };
  },
  mounted() {
    this.platinum_url = this.$detectEnvironment() + "/app/graficador-dinamico/platinum";
    this._getBestPlatinumValues();
  },
  computed: {
    ...mapGetters(["_g_UserFeatures"]),
    
    getCurrentTabValues() {
      switch(this.activeTab) {
        case 0: return this.best_values_eur;
        case 1: return this.best_values_usd;
        case 2: return this.best_values_other;
        default: return [];
      }
    }
  },
  methods: {
    _getBestPlatinumValues() {
      this.loading = true;
      
      let success = (response) => {
        this.best_values_eur = [];
        this.best_values_usd = [];
        this.best_values_other = [];
        
        (response.data || []).forEach(item => {
          if (item.divisa === "EUR") {
            this.best_values_eur.push(item);
          } else if (item.divisa === "EURUSD") {
            this.best_values_usd.push(item);
          } else {
            this.best_values_other.push(item);
          }
        });
        
        this.loading = false;
      };

      let failure = () => {
        this.loading = false;
      };

      let url = "/api/v1/stock-finder/best-platinum-values";
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(failure, '151', 'Error recuperando valores platino.');
      const apiCallConfig = { loadingOverlay: false };

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },

    _closeWidget() {
      this.$emit("widget_name", this.widget_name);
    },

    openValueGD(ticker) {
      window.open(this.$detectEnvironment() + "/app/graficador-dinamico/" + ticker, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/components/widgets/WidgetStyles.scss";

.widget-loading {
  @extend .widget-loading-base;
  background-color: var(--color-background, #0F0F1A);
}

.spinner {
  @extend .loading-spinner;
}

.platinum-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.region-tabs {
  display: flex;
  border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.08));
}

.tab-button {
  flex: 1;
  background: none;
  border: none;
  padding: 8px 12px;
  font-size: 14px;
  color: var(--color-text-secondary, #606577);
  cursor: pointer;
  position: relative;
  transition: color 0.2s ease;
  
  &:hover {
    color: var(--color-text-primary, #0f0f1a);
  }
  
  &.active {
    color: var(--primary, #00aedd);
    font-weight: 500;
    
    &::after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: var(--primary, #00aedd);
    }
  }
}

.values-container {
  flex: 1;
  overflow-y: auto;
  padding: 0;
}

.empty-list {
  @extend .empty-state;
  position: relative;
  color: var(--color-text-secondary, #606577);
  font-size: 14px;
  text-align: center;
}

.values-compact-list {
  padding: 8px 12px;
}

.value-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 8px;
  border-radius: 4px;
  margin-bottom: 4px;
  cursor: pointer;
  transition: background-color 0.15s ease;
  
  &:hover {
    background-color: rgba(0, 174, 221, 0.08);
  }
  
  &:last-child {
    margin-bottom: 0;
  }
}

.value-ticker {
  font-size: 13px;
  font-weight: 500;
  color: var(--color-text-primary, #0f0f1a);
}

.value-score {
  font-size: 14px;
  font-weight: 700;
  color: var(--primary, #00aedd);
  background-color: rgba(0, 174, 221, 0.08);
  padding: 2px 6px;
  border-radius: 4px;
  min-width: 30px;
  text-align: center;
}
</style>

