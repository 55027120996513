<template>
  <widget-wrapper
    title="Tu foto"
    widget-id="widget-your-photo"
    widget-name="Tu foto"
    @close-widget="_closeWidget"
    :is-draggable="true" 
  >
    <!-- Loading State -->
    <div v-if="loading" class="widget-loading">
      <div class="spinner"></div>
    </div>
    
    <!-- Content Area -->
    <div v-else class="your-photo-content">
      <!-- Photo Display -->
      <div v-if="has_photo" class="photo-container">
        <img :src="photo_url" alt="Foto con Jose Antonio Madrigal">
      </div>
      
      <!-- No Photo Message -->
      <div v-else class="no-photo-message">
        <span class="material-icons icon">person_off</span>
        <p>Hola <b>{{ username }}</b>,</p>
        <h3>NO TIENES FOTO CON EL PROFESOR EUREKERS</h3>
        <p>¿Tienes alguna duda? <a target="_blank" href="https://www.eurekers.com/contacta-con-nosotros">Contacta con nosotros</a></p>
      </div>
    </div>
  </widget-wrapper>
</template>

<script>
import APICaller from "@/components/codeComponents/APICaller.js";
import { mapGetters } from "vuex";
import WidgetWrapper from "@/components/widgets/WidgetWrapper.vue";

export default {
  mixins: [APICaller],
  name: 'YourPhoto',
  components: {
    WidgetWrapper
  },
  data() {
    return {
      loading: true,
      username: "",
      photo_url: "",
      widget_name: {id: "widget-your-photo", name: "Tu foto"} 
    };
  },
  computed:{
    ...mapGetters(['is_mobile_device']),
    has_photo() {
      return this.photo_url && this.photo_url !== 'Todavía no hay ninguna foto tuya con Jose Antonio Madrigal';
    }
  },
  mounted() {
    this._getYourPhoto();
  },
  methods: {
    _getYourPhoto() {
      this.loading = true;
      let success = response => {
        this.photo_url = response.data.photo_url || "";
        this.username = response.data.username || "Usuario";
        this.loading = false;
      }
      
      let failure = () => {
        console.error("Failed to load user photo");
        this.username = "Usuario";
        this.photo_url = "";
        this.loading = false;
      }

      let url = '/api/v1/widgets/get-your-photo';
                  
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(failure, '163', 'Error recuperando foto evento.');
      const apiCallConfig = {loadingOverlay: false};

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },

    _closeWidget: function(){
      this.$emit("widget_name", this.widget_name);
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/components/widgets/WidgetStyles.scss";

.widget-loading {
  @extend .widget-loading-base;
  background-color: var(--color-background, #0F0F1A);
}

.spinner {
  @extend .loading-spinner;
}

.your-photo-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100%;
  padding: 16px;
}

.photo-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 4px;
  background-color: var(--color-surface, #F5F5F5);

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: contain;
    border-radius: 4px;
  }
}

.no-photo-message {
  text-align: center;
  color: var(--color-text-secondary, #606577);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  .icon {
    font-size: 48px;
    color: var(--color-text-inactive, #BFC2CD);
    margin-bottom: 8px;
  }

  p {
    margin: 0;
    font-size: 14px;
    line-height: 1.4;
    max-width: 300px;
    
    b {
      color: var(--color-text-primary, #0f0f1a);
      font-weight: 600;
    }
  }

  h3 {
    font-size: 16px;
    font-weight: 600;
    color: var(--color-text-primary, #0f0f1a);
    margin: 8px 0;
  }

  a {
    color: var(--color-text-link, var(--primary));
    font-weight: 500;
    text-decoration: none;
    transition: color 0.2s ease;

    &:hover {
      text-decoration: underline;
      color: var(--primary-darker, #0089b0);
      opacity: 0.9;
    }
  }
}
</style>
